


































































































































import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import JTLTransferComponent from '@common-src/components/jtl-transfer/index.vue';
import ServiceCenterService from '@common-src/service/service-center';
import { UserStoreModule } from '@common-src/store/modules/user';

@Component({
    components: {
        'jtl-transfer-component': JTLTransferComponent
    }
})
export default class WorkOrderDetailComponent extends Mixins(BaseComponent) {
    @Prop()
    // 表单操作类型，继承ViewModeType
    viewType: number;

    @Prop()
    // 工单配置ID
    woConfigId: string;

    @Prop()
    // 工单ID
    woId: string;

    @Prop()
    // 报修位置id
    locationId: string;

    @Prop()
    // 报修对象id
    deviceId: string;

    @Prop()
    // 报警id
    alarmId: string;

    ViewModeType = ViewModeType;

    processModel: any = null;

    taskId: string = '';

    currentActionModel: any = undefined;

    logData: Array<any> = [];

    orderStatus: string = '';

    creator: string = '';

    UserStoreModule = UserStoreModule;

    activeTabKey: string = '1';

    moreTimeLine: boolean = false;

    serviceCenterService: ServiceCenterService;

    processInstanceId: string = '';

    // 流程图参数
    flowVisible: boolean = false;

    flowSVG: any = '';

    flowWidth: number = 1200;

    // 搁置、取消搁置、终止操作参数
    processModalVisible: boolean = false;

    processModalTitle: any = '';
    processModalContent: any = '';

    // 重新分派参数
    reAssignModalVisible: boolean = false;

    // 使用备品添加弹框
    backAssetsVisible: boolean = false;
    title: string='';
    transferData: any = [];
    keys: string[] = [];
    currentFormControl: any = undefined;

    flowStatus: string = '';

    reAssignGroup: string = '';

    reAssignPerson: string = '';

    candidateGroups: Array<any> = [];

    assignees: Array<any> = [];

    /**
     * 表单组件
     */
    @Ref()
    basicForm: FormComponent<any>;

    @Ref()
    actionForm: FormComponent<any>;

    created() {
        this.serviceCenterService = new ServiceCenterService(false);
        this.initWorkOrder();
    }

    initWorkOrder() {
        this.startFullScreenLoading();

        if (this.viewType === ViewModeType.NEW) {
            // 创建新工单
            this.serviceCenterService.getStartProperties(this.woConfigId).then(res => {
                this.initForm(res);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        } else if (this.viewType === ViewModeType.VIEW) {
            // 查看工单详情
            this.serviceCenterService.getWorkOrderProperties(this.woId).then(res => {
                this.initForm(res);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            this.serviceCenterService.getWorkOrderDetails(this.woId).then(res => {
                this.initForm(res);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }

    initForm(res) {
        if (this.viewType !== ViewModeType.VIEW) {
            this.processModel = res.nextFlows;
        } else {
            this.processModel = res.currentProcess;
        }

        this.taskId = _.first(_.map(this.processModel, item => { return item.actionFlowKey; }));

        this.processInstanceId = res.processInstanceId;

        this.logData = res.logData.reverse();

        this.orderStatus = res.orderStatus;

        this.creator = res.creator;

        this.flowStatus = res.flowStatus;

        this.formActionChange();
    }

    formActionChange() {
        this.startFullScreenLoading();

        this.currentActionModel = _.filter(this.processModel, item => {
            return item.actionFlowKey === this.taskId;
        })[0];
        if (this.currentActionModel && this.currentActionModel.fields) {
            _.forEach(this.currentActionModel.fields, item => {
                if (item.specialType === 'VOICE_DESCRIPTION' && !item.hasAudio) {
                    if (item.value) {
                        const data = _.isString(item.value) ? JSON.parse(item.value) : item.value;
                        item.value = data.descriptionText || '';
                        item.voiceRecord = data.voiceRecord || '';
                        item.hasAudio = true;
                    }
                }
                if (this.locationId || this.deviceId) { // 告警详情把报修位置和报修对象带过来
                    if (item.specialType === 'SPACE') {
                        item.value = this.locationId || '';
                    }
                    if (item.specialType === 'ASSET' || item.specialType === 'DEVICE') {
                        item.value = this.deviceId || '';
                    }
                }
            });

            this.basicForm.initFormByControls(this.currentActionModel.fields, this.viewType);
        }

        if (this.viewType !== ViewModeType.VIEW && this.orderStatus !== 'onHold' && this.orderStatus !== 'termination') {
            this.actionForm.initFormByControls(this.currentActionModel.additionalFields, this.viewType);

            if (this.currentActionModel.assignment.assignmentType === 'Profession') {
                this.switchProfession();
            }
        } else {
            this.activeTabKey = '2';
        }
        this.stopFullScreenLoading();
    }

    formModelChanged(formControl) {
        // // 根据资产填充专业
        // let deviceFormId = this.basicForm.formControlList.filter(item => { return item.specialType === 'device'; })[0].key;

        // if (formControl.key === deviceFormId) {
        //     let deviceId = this.basicForm.formModel[deviceFormId];

        //     CommonService.getProfessional(deviceId).then(res => {
        //         this.basicForm.formModel[professionalFormId] = res[0].key;
        //     }).catch(() => {
        //         this.basicForm.formModel[professionalFormId] = undefined;
        //     });
        //     // if (deviceId === '' || !deviceId) {
        //     //     CommonService.getProfessionalTree().then(res => {
        //     //         professionalFormItem.options = res;
        //     //     });
        //     //     professionalFormItem.relationPath = undefined;
        //     // } else {
        //     //     professionalFormItem.optionsPromise = undefined;
        //     //     CommonService.getProfessional(deviceId).then(res => {
        //     //         professionalFormItem.options = res;
        //     //         this.basicForm.formModel[professionalFormId] = res[0].key;
        //     //     });
        //     // }
        // }
        // 根据专业填充受理组、受理人
        if (this.currentActionModel.assignment.assignmentType === 'Profession' && formControl.key === 'profession') {
            this.switchProfession();
        }
    }

    switchProfession() {
        let professionalId = this.basicForm.formModel['profession'];

        if (professionalId) {
            let candidateGroupFormItem = this.actionForm.formControlList.filter(item => { return item.key === 'candidateGroup'; })[0];

            if (candidateGroupFormItem) {
                CommonService.getCandidate(professionalId).then(res => {
                    // 专业有配置的受理组或受理人
                    if (res && res.orgId) {
                        this.actionForm.formModel['candidateGroup'] = res.orgId;

                        candidateGroupFormItem.value = res.orgId;

                        //  candidateGroupFormItem.options = [new SimpleTreeModel().toModel({ id: res.orgId, name: res.orgName })];
                        let assigneeFormItem = this.actionForm.formControlList.filter(item => { return item.key === 'assignee'; })[0];

                        if (assigneeFormItem) {
                            if (res.userId) {
                                // assigneeFormItem.options = [{ value: res.userId, name: res.userName }];
                                this.actionForm.formModel['assignee'] = res.userId;
                            } else {
                                CommonService.getOrgUserList(res.orgId).then(res => {
                                    assigneeFormItem.options = res;
                                });
                            }
                        }
                    } else {
                        // 默认受理组或受理人
                        this.actionForm.formModel['candidateGroup'] = candidateGroupFormItem.specialType;

                        candidateGroupFormItem.value = candidateGroupFormItem.specialType;

                        let assigneeFormItem = this.actionForm.formControlList.filter(item => { return item.key === 'assignee'; })[0];

                        if (assigneeFormItem) {
                            if (assigneeFormItem.specialType) {
                                // assigneeFormItem.options = [{ value: res.userId, name: res.userName }];
                                this.actionForm.formModel['assignee'] = assigneeFormItem.specialType;
                            } else {
                                CommonService.getOrgUserList(candidateGroupFormItem.specialType).then(res => {
                                    assigneeFormItem.options = res;
                                });
                            }
                        }
                    }
                }).catch(() => {
                    this.actionForm.formModel['candidateGroup'] = undefined;
                });
            }
        }
    }

    submitForm() {
        this.startFullScreenLoading();
        return Promise.all([this.basicForm.submitForm(), this.actionForm.submitForm()]).then(res => {
            _.map(this.basicForm.formControlList, item => {
                if (item.type === 'TABLE') {
                    if (item.cellType) {
                        let tableValue = _.map(item.cellType, (type, typeIndex) => {
                            let rowData = _.map(Object.keys(type), typeKey => {
                                if (type[typeKey].type === 'CHECKBOX' && !item.readonly) {
                                    return {
                                        type: type[typeKey].type,
                                        code: typeKey,
                                        value: this.basicForm.formModel[item.key][typeIndex][typeKey] > 0 ? 1 : 0,
                                        visible: type[typeKey].visible
                                    };
                                } else {
                                    return {
                                        type: type[typeKey].type,
                                        code: typeKey,
                                        value: this.basicForm.formModel[item.key][typeIndex][typeKey],
                                        visible: type[typeKey].visible
                                    };
                                }
                            });

                            return rowData;
                        });

                        this.basicForm.formModel[item.key] = {
                            data: tableValue,
                            tableColumns: item.columns
                        };
                    } else {
                        this.basicForm.formModel[item.key] = {
                            // data: [],
                            data: item.value || [],
                            tableColumns: item.columns
                        };
                    }
                    // this.basicForm.formModel[item.key] = {
                    //     data: this.basicForm.formModel[item.key],
                    //     tableColumns: item.columns
                    // };
                } else if (item.specialType === 'VOICE_DESCRIPTION') {
                    this.basicForm.formModel[item.key] = JSON.stringify({
                        descriptionText: this.basicForm.formModel[item.key] || '',
                        voiceRecord: item.voiceRecord || ''
                    });
                }
            });
            // const model = Object.assign(this.basicForm.formModel, this.actionForm.formModel);

            if (this.viewType === ViewModeType.NEW) {
                this.serviceCenterService.saveFirstForm(this.taskId, this.basicForm.formModel, this.actionForm.formModel, this.woConfigId, this.alarmId).then(res => {
                    this.hideDrawer();
                    if (this.alarmId) {
                        this.$emit('updateAlarmDetail');
                    }
                });
            } else {
                this.serviceCenterService.saveForm(this.taskId, this.basicForm.formModel, this.actionForm.formModel, this.woId).then(res => {
                    this.hideDrawer();
                });
            }
        }).catch(() => {
            this.stopFullScreenLoading();
        });
    }

    /* 查看流程图 */
    showSVG() {
        this.startFullScreenLoading();

        this.serviceCenterService.getFlowSVG(this.processInstanceId).then(res => {
            this.flowVisible = true;

            var parser = new DOMParser();

            this.flowSVG = parser.parseFromString(res, 'text/xml');

            this.flowWidth = this.flowSVG.children[0].width.baseVal.value + 200;
        }).finally(() => {
            let flowDiv = document.getElementById('flow');

            flowDiv.append(this.flowSVG.documentElement);

            this.stopFullScreenLoading();
        });
    }

    handleOk() {
        this.flowVisible = false;
    }

    handleCancel() {
        this.flowVisible = false;
    }

    /* 重新分派 */
    showReAssignModal() {
        this.reAssignModalVisible = true;

        CommonService.getOrgs().then(res => {
            this.candidateGroups = res;
        });
    }

    groupChanged(value) {
        CommonService.getOrgUserList(value).then(res => {
            this.assignees = res.map(item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });
        });
    }

    handleReAssignOk() {
        if (this.reAssignGroup === '') {
            this.showMessageWarning('受理组为必选项');

            return;
        }

        this.serviceCenterService.reAssign(this.woId, this.flowStatus, this.reAssignPerson, this.reAssignGroup).then(res => {
            this.showMessageSuccess('分派成功');

            this.hideDrawer();
        });
    }

    handleReAssignCancel() {
        this.reAssignModalVisible = false;
    }
    /* 搁置、取消搁置、终止 */
    showModal(title) {
        this.processModalVisible = true;

        this.processModalTitle = title;
    }

    handleProcessOk() {
        if (!this.processModalContent && this.processModalTitle !== '取消搁置') {
            this.showMessageWarning('原因未必填项，请填写!');
            return;
        }

        let processStatus = '';

        switch (this.processModalTitle) {
            case '搁置':
                processStatus = 'onHold';
                break;
            case '取消搁置':
                processStatus = 'resume';
                break;
            case '终止':
                processStatus = 'termination';
                break;
            default:
                break;
        }

        this.serviceCenterService.processWorkOrder(this.woId, this.processModalContent, processStatus).then(res => {
            this.hideDrawer();
        });
    }

    formTableAdd(item) {
        if (item) {
            this.currentFormControl = item;
            this.title = item.label;
            if (item.dataService) {
                item.dataService().then(res => {
                    this.transferData = res.map(dt => {
                        return { key: dt.value, title: dt.name, type: dt.type };
                    });
                    this.keys = this.basicForm.formModel[this.currentFormControl.key].map(dt => { return dt.key; });
                    this.backAssetsVisible = true;
                });
            }
        }
    }
    handleBackAssetsOk() {
        this.basicForm.formModel[this.currentFormControl.key] = this.$refs['transfer']['targetData'];
        this.currentFormControl.value = this.$refs['transfer']['targetData'];
        this.backAssetsVisible = false;
    }
    handleBackAssetsCancel() {
        this.backAssetsVisible = false;
    }
    handleProcessCancel() {
        this.processModalVisible = false;
        this.processModalContent = '';
    }

    hideDrawer() {
        this.showMessageSuccess('提交成功');
        this.$eventHub.$emit('GET_WORKORDER_NUMBERS');
        this.$emit('showDrawer', { type: 'workorder', visible: false });
        this.stopFullScreenLoading();
    }
}
